<div mat-dialog-content>
    <div class="radio-buttons">
        <label>
            <input type="radio" name="selectOrders" [(ngModel)]="selectAllByFilter" [value]="true"
                (change)="onSwitchChange($event)" />
            Izvezi sve poružbine prema zadatom filteru
        </label><br>
        <label>
            <input type="radio" name="selectOrders" [(ngModel)]="selectAllByFilter" [value]="false"
                (change)="onSwitchChange($event)" />
            Izvezi samo odabrane poružbine
        </label>
    </div>
</div>
<div mat-dialog-actions class="vertical-buttons">
    <button mat-button (click)="selectOption('export')">Potvrdi</button>
</div>  