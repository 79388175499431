<h1 mat-dialog-title>Odaberite štampu</h1>
<div mat-dialog-content>
    <p>Odaberite koju vrstu štampe želite:</p>
    
    <!-- Switch Selector -->
    <div class="radio-buttons">
        <label>
          <input
            type="radio"
            name="selectOrders"
            [(ngModel)]="selectByFilter"
            [value]="true"
            (change)="onSwitchChange($event)"
          />
          Štampaj sve poružbine prema zadatom filteru
        </label><br>
        <label>
          <input
            type="radio"
            name="selectOrders"
            [(ngModel)]="selectByFilter"
            [value]="false"
            (change)="onSwitchChange($event)"
          />
          Štampaj samo odabrane poružbine
        </label>
      </div>      
  </div>
<div mat-dialog-actions class="vertical-buttons">
  <button mat-button (click)="selectOption('order-details')">Detalji poružbina</button>
  <button mat-button (click)="selectOption('dispatch-list')">Lista za dostavu</button>
  <button mat-button (click)="selectOption('dispatch-note')">Otpremnice</button>
  <button mat-button (click)="selectOption('orders-articles-quantities')">List artikala</button>
</div>