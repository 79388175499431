import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-export-options',
  templateUrl: './export-options.component.html',
  styleUrls: ['./export-options.component.sass']
})
export class ExportOptionsComponent {
  constructor(public dialogRef: MatDialogRef<ExportOptionsComponent>) {}

  selectAllByFilter = false;

  onSwitchChange(event: any): void {
    console.log('Switch changed:', this.selectAllByFilter);
  }

  selectOption(option: string): void {
    this.dialogRef.close({option, switchValue: this.selectAllByFilter});
  }
}
