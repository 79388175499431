import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';

//Core Components
import { RouterModule } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';

//Material
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { HomeComponent } from './components/home/home.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PwaPromptComponent } from './components/pwa-prompt/pwa-prompt.component';
import { SharedModule } from '../shared/shared.module';
import { FileUploadButtonComponent } from './components/file-upload-button/file-upload-button.component';
import { FileDownloadButtonComponent } from './components/file-download-button/file-download-button.component';
import { CountryCodeSelectorComponent } from './components/country-code-selector/country-code-selector.component';
import { LocationControlComponent } from './components/location-control/location-control.component';
import { DeliveryTimeComponent } from './components/delivery-time/delivery-time.component';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';


@NgModule({
  declarations: [
    NavigationComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    ClickStopPropagationDirective,
    HomeComponent,
    SpinnerComponent,
    PwaPromptComponent,
    FileUploadButtonComponent,
    FileDownloadButtonComponent,
    CountryCodeSelectorComponent,
    LocationControlComponent,
    DeliveryTimeComponent,
    PageNotFoundComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    LayoutModule,
    RouterModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    // NgxPrintModule
  ],
  exports: [
    FileUploadButtonComponent,
    FileDownloadButtonComponent,
    CountryCodeSelectorComponent,
    DeliveryTimeComponent
    // NgxPrintModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DatePipe
  ]
})
export class CoreModule { }
