import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { LoginModule } from './login/login.module';
// import { NgxsFirestoreModule } from '@ngxs-labs/firestore-plugin';
import { NgxsModule } from '@ngxs/store';
import { PwaService } from './core/services/pwa.service';
import { AuthService } from './auth/services/auth.service';
import { VersionService } from './core/services/version.service';
import { HttpClientModule } from '@angular/common/http';
import { UserOrganizationsState } from './profile/states/user-organizations/user-organizations.state';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth, connectAuthEmulator } from '@angular/fire/auth';
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { MatIconModule } from '@angular/material/icon';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from './shared/shared.module';
import { LoadingService } from './shared/services/loading.service';

export function fetchVersion(buildDetailsService: VersionService) {
  return () => buildDetailsService.getVersion();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatIconModule,
    MatFormFieldModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
    provideAuth(() => {
      const auth = getAuth();
      if (isDevMode()) {
        connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
      }
      return auth;
    }),
    AngularFireMessagingModule,
    // environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([
      UserOrganizationsState
    ],
      { developmentMode: !environment.production }),
    CoreModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    ServiceWorkerModule.register(environment.production ? 'main-sw-dev.js' : 'main-sw.js', {
      // ServiceWorkerModule.register('main-sw-dev.js', {
      enabled: environment.production,
      // enabled: true,

      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
      registrationStrategy: 'registerImmediately'
    }),
    LoginModule,
    AppRoutingModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' })
  ],
  providers: [
    AngularFirestore,
    LoadingService,
    // { provide: SwRegistrationOptions, useFactory: () => ({ enabled: environment.production }) },
    {
      provide: APP_INITIALIZER,
      useFactory: (pwaService: PwaService) => () => pwaService.initPwaPrompt(),
      deps: [PwaService, AuthService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: fetchVersion,
      deps: [VersionService],
      multi: true
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
