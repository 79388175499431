export const environment = {
  production: true,
  swEnable: true,
  firebase: {
    apiKey: "AIzaSyDGXPpqqRyiMvxyiXN_PUain3RY_6MUCCE", //prod
    authDomain: "mprocesses-fb-prod-app-rc.firebaseapp.com",
    projectId: "mprocesses-fb-prod",
    storageBucket: "mprocesses-fb-prod.appspot.com",
    messagingSenderId: "1079770724232",
    appId: "1:1079770724232:web:cdc12bd11b5229a4b36c3d",
    measurementId: "G-1T2MWG06YJ"
  },
  vapidKey: "BNJVVZWVGHKg2peZcbnQdLoJB2hG-T837aPcEHkM6xYkh3_-ucxHzxTPo3zeCTMu_4GiT_7SfsloraTWIgL-640",
  useEmulators: false,
  typesenseHost: 'typesense.prod.tefter.com',
  typesensePort: 443,
  typesenseProtocol: 'https',
  baseUrl: 'https://business.rc-prod.tefter.com',
  appName: "Tefter",
  singleOrgId: "",
  procurementSingleSupplierId: ""
};
